import React, { useEffect, useState } from 'react';
import Top from './Top';
import Header from '../components/Header';
import { Select, Input, Button, Modal, Pagination  } from 'antd';
import { bankCodeList } from '../lib/util/codeUtil';
import { httpGet, httpPost, httpUrl } from '../api/httpClient';
import { useSelector, useDispatch } from "react-redux";
import { setCoinBalance, setCoinList, setCoinCurrency } from "../actions";
import SHA256 from 'crypto-js/sha256';
import { enc } from 'crypto-js';
import moment from "moment";
import Const from '../const';

const Trade = () => {
    const dispatch = useDispatch();
    const userinfo = useSelector(state => state.loginReducer.userinfo); 
    const coinList = useSelector(state => state.coinListReducer);
    const string = useSelector(state => state.stringReducer.string);
    const [page, setPage] = useState({
        totalCount:0,
        pageSize: 5,
        current: 1,
    });
    const [list, setList] = useState([]);
    const [inputs, setInputs] = useState({
        coinType: 101,
        bank:'',
        bankAddress:'',
        bankName:'',
        amount:0,
        secPassword:''
    })
    const { bank, bankAddress, amount, bankName, secPassword } = inputs;
    useEffect(()=>{
        getList();
        requestBalance();
        
        httpGet(httpUrl.myinfo, [], {}, true).then((result) => {
            setInputs({...inputs, 
                bank:result.data.bankCode,
                bankAddress:result.data.bankAccount,
                bankName:result.data.accountHolder
            })
        });
    },[])
    const requestBalance = async () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [101], {}).then((result) => {
            result = result.data;
            console.log(result)
            dispatch(setCoinBalance(result));
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    };
    const onSubmit = async() => {
        if (bank === '' || bankAddress === ''|| amount < 1 || bankName === '' || secPassword === '') {
            Modal.error({
                title: string.error,
                content: string.bankWithdrawInputError
            })
            return;
        }
        if (coinList.find(x=>x.coinType == 101).balance < amount) {
            Modal.error({
                title: string.error,
                content: string.sendCoinFailedInsufficientBalance
            })
            return;
        }
        let secPasswordHash =   SHA256(secPassword + userinfo.id).toString(enc.Hex);

        Modal.confirm({
            title: string.bankWithdrawApply,
            content: amount + ", " + string.bankWithdrawApplyConfirm,
            okText: string.ok,
            cancelText: string.cancel,
            onOk(){
                httpPost(httpUrl.withdrawRequest, [], {
                    bank: bank, 
                    bankAddress: bankAddress, 
                    amount:amount, 
                    bankName:bankName,
                    coinType: 101,
                    securityPassword: secPasswordHash
                }).then((res)=>{
                    if (res.data == "SUCCESS" && res.result == "SUCCESS") {
                        setInputs({
                            ...inputs,
                            coinType: 101,
                            amount:0,
                            secPassword:''
                        })
                        getList();
                        requestBalance();
                        Modal.info({
                            title: string.bankWithdrawApply,
                            content: string.bankWithdrawApplySuccess
                        })

                    } else{
                        let message = string.errorDetail
                        if (res.data == "INVALID_AMOUNT") message = string.bankWithdrawApplyInvalidAmount;
                        else if (res.data == "INVALID_SECURITY_PASSWORD") message = string.bankWithdrawApplyInvalidSecPassword;
                        else if (res.data == "INSUFFICIENT_BALANCE") message = string.sendCoinLowBalance;
                        else if (res.data == "LOCKED") message = string.sendCoinLowBalance;
                        else if (res.data == "MACHINE_CONNECTED") message = string.bankWithdrawApplyConnected;
                        Modal.info({
                            title: string.bankWithdrawApply,
                            content: message
                        })
                        return;
                    }
                })},
            oncancel(){
                return;
            }           
        });
    };
    const getList = async (current) => {
        if (current == null) current = page.current;
        const res = await httpGet(httpUrl.getBankWithdrawList, [current, page.pageSize], {});
        if (res.data.list.length > 0) {
            setList(res.data.list);
            setPage({...page, totalCount: res.data.totalCount, current});
        }
    };
    const handleTableChange = (current) => {   
        getList(current)
    };
    // const onDeleted = (idx) => {       
    //     Modal.confirm({
    //         title: "거래 신청 취소",
    //         content: "신청을 취소하시겠습니까? 확인을 누르시면 내역은 삭제됩니다.",
    //         okText: string.ok,
    //         cancelText: string.cancel,
    //         onOk(){
    //             httpPost(httpUrl.deletedWithdraw, [idx], {}).then((res)=>{
    //                 if (res.data == "SUCCESS" && res.result == "SUCCESS") {
    //                     alert("신청이 취소되었습니다.")
    //                     getList();
    //                 } else{
    //                     alert("신청에 오류가 생겼습니다. 관리자에게 문의해주세요.")
    //                     return;
    //                 }
    //             })},
    //         oncancel(){
    //             return;
    //         }           
    //     });
    // }
    return (
        <div id='trade'>
            <Top />
            <Header />           
            <div className='inputSection'>
                <div className='inputBox'>
                    <p className='name'>{string.coin} ({string.myValue}: {coinList.find(x=>x.coinType == 101).balance} BLC)</p>
                    <Select
                        defaultValue={coinList.find(x=>x.coinType == 101).name}
                        style={{ width: '100%' }}
                        options={[
                            { value: '101', label: coinList.find(x=>x.coinType == 101).name },                           
                        ]}/>
                </div>
                <div className='inputBox'>
                    <p className='name'>{string.bankWithdrawBank}</p>
                    <Input 
                    placeholder={string.bankWithdrawBankInput}
                    name="bankName"
                    value={bank}
                    onChange={(e)=>{
                        setInputs({...inputs, bank: e.target.value })
                    }} />

                    {/* <Select
                       style={{ width: '100%' }}
                        onChange={(e) => { onChangeBank(e) }}
                        placeholder="은행을 선택해주세요"                        
                    >
                        {bankCodeList.map((value, index) => {
                            return (
                                <Select.Option value={value} key={index}> {value} </Select.Option>
                            );
                        })}
                    </Select> */}
                </div>
                <div className='inputBox'>
                    <p className='name'>{string.bankWithdrawDepositor}</p>
                    <Input 
                    placeholder={string.bankWithdrawDepositorInput}
                    name="bankName"
                    value={bankName}
                    onChange={(e)=>{
                        setInputs({...inputs, bankName: e.target.value })
                    }} />
                </div>
                <div className='inputBox'>
                    <p className='name'>{string.bankWithdrawAccount}</p>
                    <Input 
                    placeholder={string.bankWithdrawAccountInput}
                    name="bankAddress"
                    value={bankAddress}
                    onChange={(e)=>{
                        let address = e.target.value.replace(/[^0-9]/g,'')
                        setInputs({...inputs, bankAddress: address })
                    }} />
                </div>
                <div className='inputBox'>
                    <p className='name'>{string.insertAmount}</p>
                    <Input 
                    placeholder={string.pleaseInsertAmount}
                    name="amount"
                    value={amount}
                    onChange={(e)=>{
                        let a = e.target.value.replace(/[^0-9]/g,'')
                        setInputs({...inputs, amount: a })
                    }} />

                </div>
                <div className='inputBox'>
                    <p className='name'>{string.securityPw}</p>
                    <Input 
                    placeholder={string.pleaseInsertSecurityPw}
                    name="secPassword"
                    value={secPassword}
                    onChange={(e)=>{
                        setInputs({...inputs, secPassword: e.target.value })
                    }} />
                </div>
                <Button className='btn' onClick={onSubmit}>{string.bankWithdrawApply}</Button>
            </div>            
            <div className='table'>
                <p className='title'>{string.tradeLog}</p>
                <div className='withdrawColumn'>
                    <div>{string.bankWithdrawStatus}</div>
                    <div>{string.bankWithdrawBank}</div>
                    <div>{string.bankWithdrawAccount}</div>
                    <div>{string.bankWithdrawApplyAmount}</div>
                    <div>{string.bankWithdrawApplyTime}</div>
                    {/* <div>삭제</div> */}
                </div>
                {list.map((item)=>{
                    return(
                        <div key={item.idx} className='withdrawRow'>
                            <div className={'status'+item.status}>{item.status === 0 ? string.bankWithdrawApply : item.status === 1 ? string.completed : string.cancel}</div>
                            <div>{item.bank}</div>
                            <div>{item.bankAddress}</div>
                            <div>{item.amount}</div>
                            <div>{moment(item.createDate).format("YYYY-MM-DD hh:mm:ss")}</div>
                            {/* <div><Button onClick={()=>onDeleted(item.idx)} disabled={item.status > 0}>삭제</Button></div> */}
                        </div>
                    )
                })}               
            </div>
            <div className='custompage'>
                <Pagination current={page.current} onChange={handleTableChange} defaultPageSize={page.pageSize} total={page.totalCount} />
            </div> 
        </div>
    )
}
export default Trade;
